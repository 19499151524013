<template>
  <div>
    <el-card class="box-card">
      <template #header>
        <div class="card-header">
          <el-form :inline="true" :model="formInline" class="demo-form-inline" ref="searchForm">
            <el-form-item :label="$t('index.device_type')" prop="type">
              <el-select clearable :placeholder="$t('index.device_type')" v-model="formInline.type">
                <el-option
                  :key="item.value"
                  :label="item.text"
                  :value="item.value"
                  v-for="item in deviceType"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('device.device_status')" prop="online">
              <el-select :placeholder="$t('device.device_status')" v-model="formInline.online">
                <el-option :label="$t('index.offline')" value="0"></el-option>
                <el-option :label="$t('index.online')" value="1"></el-option>
                <el-option :label="$t('index.stripping_outline')" value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="imei">
              <el-input :placeholder="$t('device.input_keywords')" v-model="formInline.imei"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button @click="searchForm" type="primary">{{ $t('device.inquire') }}</el-button>
              <el-button @click="resetForm" type="primary">{{ $t('device.reset') }}</el-button>
            </el-form-item>
          </el-form>
        </div>
      </template>
      <el-row :gutter="20">
        <el-col :span="6">
          <div class="grid-content">
            <el-table :data="device" border max-height="600">
              <el-table-column label="设备IMEI" prop="imei"></el-table-column>
              <el-table-column :label="$t('device.status')" prop="online" width="100px"></el-table-column>
            </el-table>
            <el-pagination
              background
              small
              layout="prev, pager, next"
              :total="pageTotal"
              :current-page="formInline.page"
              :page-size="formInline.size"
              @current-change="handleCurrentChange"
              @size-change="handleSizeChange"
              fixed="right"
            ></el-pagination>
          </div>
        </el-col>
        <el-col :span="18">
          <div class="grid-content bg-purple" style="position:relative;">
            <div id="container" style="width:100%;min-height:600px;"></div>
            <div class="deviceMes" v-show="isShow">
              <p>
                <span>{{ $t('index.device_type') }}</span>
                {{type}}
              </p>
              <p>
                <span>设备IMEI</span>
                {{imei}}
              </p>
              <p>
                <span>{{ $t('index.location_info') }}</span>
                {{location}}
              </p>
              <p>
                <span>{{ $t('index.battery_level') }}</span>
                {{volt}}
              </p>
              <p>
                <span>{{ $t('device.position_time') }}</span>
                {{location_time}}
              </p>
              <p>
                <span>{{ $t('index.comm_time') }}</span>
                {{update_at}}
              </p>
            </div>
          </div>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>
<script>
import device from "../../model/device";
import AMapLoader from "@amap/amap-jsapi-loader";

export default {
  name: "location",
  data() {
    return {
      pageTotal:0,
      formInline: {
        imei: "",
        type: "",
        online: "",
        page: 1,
        size: 10
      },
      deviceType: [],
      device: [],
      type: "",
      imei: "",
      location: "",
      volt: "",
      location_time: "",
      update_at: "",
      isShow: false
    };
  },
  mounted() {
    this.deviceTypeArr();
    this.loadData();
  },
  methods: {
    async loadData() {
      const ret = await device.map(this.formInline);
      if (ret.code === 200) {
        this.device = ret.data.list;
        this.pageTotal = ret.data.count
        this.loadMap();
      } else {
        this.$message.warning(ret.message);
      }
    },
    async deviceTypeArr() {
      const ret = await device.config();
      if (ret.code === 200) {
        this.deviceType = ret.data;
      }
    },
    searchForm() {
      this.loadData();
    },
    resetForm() {
      this.$refs.searchForm.resetFields();
      this.loadData();
    },
    loadMap() {
      AMapLoader.load({
        key: this.$store.state.amap_key, // 申请好的Web端开发者Key，首次调用 load 时必填
        version: this.$store.state.amap_version, // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [] //插件列表
      })
        .then(AMap => {
          let map = new AMap.Map("container", {
            // center: [this.longitude, this.latitude],
            resizeEnable: true,
            zoom: 4
          });
          let trafficLayer = new AMap.TileLayer.Traffic({
            zIndex: 2
          });
          if (this.device.length == 1) {
            map.setZoom(15);
            map.setCenter([this.device[0].longitude, this.device[0].latitude]);
          }
          let marker = [];
          this.device.forEach(v => {
            if (v.location_time) {
              let m = new AMap.Marker({
                icon:
                  v.is_online !== 1
                    ? this.$store.state.outline
                    : this.$store.state.online,
                position: [v.longitude, v.latitude],
                title: v.imei
              });
              marker.push(m);

              m.on("click", e => {
                console.log(e)
                this.isShow = true;
                this.device.forEach(v => {
                  if (v.imei == e.target.De.title) {
                    this.type = v.type;
                    this.imei = v.imei;
                    this.volt = v.volt;
                    this.location = v.location;
                    this.location_time = v.location_time;
                    this.update_at = v.update_at;
                    return;
                  }
                });
              });
            }
          });
          // 将以上覆盖物添加到地图上
          // 单独将点标记添加到地图上
          map.add(marker);
          map.add(trafficLayer); //添加图层到地图
          map.plugin(["AMap.MarkerClusterer"], function() {
            new AMap.MarkerClusterer(map, marker);
          });
        })
        .catch(e => {
          console.log(e);
        });
    },
    handleSizeChange(val) {
      this.formInline.size = val;
      this.loadData();
    },
    handleCurrentChange(val) {
      this.formInline.page = val;
      this.loadData();
    }
  }
};
</script>
<style scoped>
.demo-form-inline {
  margin-top: 20px;
}
.card-header {
  display: flex;
  align-items: center;
}
.el-table {
  margin-top: 20px;
}
.el-pagination {
  margin: 30px 0px;
  float: right;
}

.book-mains::-webkit-scrollbar {
  width: 4px;
}
.book-mains::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px #f4f4f4;
  background: #f4f4f4;
}
.book-mains::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px #f4f4f4;
  border-radius: 0;
  background: #f4f4f4;
}
.deviceMes {
  position: absolute;
  right: 13%;
  top: 0px;
  background: rgba(255, 255, 255, 0.8);
  width: auto;
  padding: 20px 20px 10px 0px;
  height: 200px;
  z-index: 999;
  padding-top: 15px;
}
.deviceMes p {
  line-height: 30px;
  color: #999999;
}
.deviceMes p span {
  display: inline-block;
  width: 80px;
  margin-right: 20px;
  text-align: right;
  color: #333333;
}
</style>
